import styled from "@emotion/styled";
import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Badge, Box, Button, Flex, Heading, Spinner, Text } from "theme-ui";
import Input from "../../../components/form-fields/Input";
import IconShoppingCart from "../../../components/icons/icon-shopping-cart";
import { useInfiniteQuery, useQuery } from "react-query";
import {
  getCategories,
  getOrderDetail,
  getStockList,
  lockOrder,
} from "../../../apis";
import MultiCheckboxFilter from "./MultiCheckboxFilter";
import RadioFilter from "./RadioFilter";
import IconSearch from "../../../components/icons/icon-search";
import IconClose from "../../../components/icons/icon-close";
import Cart from "./Cart";
import { useCartActions, useCartState } from "../../../contexts/CartContext";
import SortTableHead from "./SortTableHead";
import { useHistory, useLocation } from "react-router-dom";
import qs from "qs";
import Skeleton from "../../../components/Skeleton";
import ShopTableRow from "./ShopTableRow";
import { useTranslation } from "react-i18next";
import MobileViewProductItem from "./MobileViewProductItem";
import IconFilter from "../../../components/icons/icon-filter";
import IconSortUp from "../../../components/icons/icon-sort-up";
import { lighten, transparentize } from "@theme-ui/color";
import Menu from "../../../components/Menu";
import IconSortDown from "../../../components/icons/icon-sort-down";
import WithLoader from "../../../components/WithLoader";
import { MobileCartContext } from "../../../contexts/MobileCartProvider";
import exceptionHandler from "../../../apis/exceptionHandler";
import { useToast } from "../../../contexts/AlertProvider";
import { Waypoint } from "react-waypoint";
import useMediaQuery from "../../../utils/useMediaQuery";
import YPWhite from "../../../assets/images/YP-white.png";
import IconTruck from "../../../components/icons/icon-truck";
import { useCOAStatus } from "../../../contexts/COAProvider";
import IconAlert from "../../../components/icons/icon-alert";
import { useEditMode } from "../../../contexts/EditModeProvider";
import {
  PageRoutes,
  refetchIntervalValues,
  RequestStatus,
  SortDirection,
  StockListSortyBy,
} from "../../../constants";
import themes from "../../../themes";

const ShopPageContainer = styled(Box)`
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: auto 35rem;
  padding: 0 2.5rem;
  margin: 1rem 0;

  .status-indicator {
    display: block;
    height: 1rem;
    width: 1rem;
    min-width: 1rem;
    background-color: ${(props) => props.theme.colors.primary};
    border-radius: 50%;
    margin-right: 0.75rem;

    &.available {
      background-color: ${(props) => props.theme.colors.success};
    }

    &.upcoming {
      background-color: ${(props) => props.theme.colors.warning};
    }

    &.outofstock {
      background-color: ${(props) => props.theme.colors.danger};
    }
  }

  .pagination-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    border: 1px solid ${(props) => props.theme.colors.primary};
    padding: 0.5rem 1rem;
  }

  @media (max-width: 1320px) {
    grid-template-columns: 1fr;
  }

  ${(props) => props.theme.customBreakpoints[1]} {
    padding: 0 1rem;
    margin-top: 2rem;
  }

  // shared styles for drawer views for cart, filter, sort

  .drawer-wrapper {
    display: flex;
    align-items: flex-start;
    transition: all 0.4s ease;

    .outside-click {
      display: none;
      flex: 1 1 auto;
      background: transparent;
    }

    .header {
      .icon-btn {
        display: none;
      }
    }

    &.drawer-filter {
      position: fixed;
      top: 0;
      bottom: 0;
      left: -100%;
      z-index: 100;
      width: 100%;
      align-items: stretch;

      .outside-click {
        display: block;
      }

      &.opened {
        left: 0;
      }
    }

    @media (max-width: 1320px) {
      &.drawer-cart {
        position: fixed;
        top: 0;
        bottom: 0;
        right: -100%;
        z-index: 100;
        width: 100%;
        align-items: stretch;

        .outside-click {
          display: block;
        }

        &.opened {
          right: 0;
        }
      }
    }
  }

  @media (max-width: 1320px) {
    .drawer-wrapper.drawer-cart {
      .drawer-container {
        max-height: none;
        max-width: 30rem;
        padding: 1rem;
        width: 100%;

        background-color: ${(props) => props.theme.colors.white};
        border-radius: 0;
        border: none;
        box-shadow: ${(props) => props.theme.shadows.dropDown};

        .header {
          font-size: 1.25rem;
          padding-top: 1.5rem;
          padding-bottom: 1.5rem;
          margin-bottom: 1.5rem;
          position: relative;

          .icon-btn {
            display: flex;
            position: absolute;
            right: 1rem;
            top: 50%;
            transform: translateY(-50%);

            .svg-icon {
              margin: 0;
            }
          }
        }

        .cart-list-container {
          max-height: calc(100vh - 26rem);
        }

        @media (orientation: landscape) and (max-height: 40rem) {
          overflow-y: auto;
          .cart-list-container {
            max-height: 20rem;
          }
        }
      }
    }
  }
`;

const ProductContainer = styled(Box)`
  width: 100%;

  .desktop-search-container {
    margin-bottom: 1rem;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: auto 1fr auto;

    .input-container {
      .input-icon {
        width: 3rem;
      }

      .input {
        border-radius: 2rem;
        padding-right: 3rem;
        padding-left: 1.5rem;
        background-color: ${(props) => props.theme.colors.lightGray};

        &::placeholder {
          color: ${(props) => props.theme.colors.primaryText};
        }
      }
    }

    .filter-toggle {
      min-width: 8rem;
      position: relative;

      .filter-indicator {
        position: absolute;
        right: -0.25rem;
        top: -0.25rem;
      }
    }

    .cart-toggle {
      display: none;
    }
  }

  .mobile-search-container {
    display: none;
    margin-top: -2rem;
    margin-left: -1rem;
    margin-right: -1rem;
    position: sticky;
    top: 4rem;
    z-index: 10;

    .input-container {
      .input-icon {
        width: 3rem;

        .svg-icon {
          height: 1.5rem;
          width: 1.5rem;
        }
      }

      .input {
        border-radius: 0;
        width: 100%;
        padding: 1rem 3rem 1rem 1rem;
        font-size: 1.125rem;
        background-color: ${(props) => props.theme.colors.gray};

        &::placeholder {
          color: ${(props) => props.theme.colors.secondaryText};
        }
      }
    }

    .filter-sort-container {
      display: flex;
      background-color: ${(props) => props.theme.colors.white};

      .filter-toggle,
      .sort-toggle {
        border-radius: 0;
        border: none;
        padding: 0.75rem 1.5rem;
        height: auto;
        display: flex;
        align-items: center;
        flex: 1 1 auto;
        width: 100%;
        font-size: 1.125rem;
        text-transform: capitalize;
        color: ${(props) => props.theme.colors.primaryText};
        background-color: ${(props) => props.theme.colors.primaryLight};
        position: relative;

        > .svg-icon {
          fill: ${(props) => props.theme.colors.secondaryText};
          height: 1.5rem;
          width: 1.5rem;
          margin-left: 0.5rem;
          margin-right: 0;
        }

        &:hover {
          .svg-icon {
            fill: ${(props) => props.theme.colors.primaryText};
          }
        }

        &.active {
          > .svg-icon {
            fill: ${(props) => props.theme.colors.secondary};
          }
        }
      }

      .sort-toggle {
        justify-content: center;

        .dropdown-label-block {
          .svg-icon {
            fill: ${(props) => props.theme.colors.secondaryText};
            height: 1.5rem;
            width: 1.5rem;
            margin-left: 0.5rem;
            margin-right: 0;
          }
        }

        .dropdown-content {
          .menu-item {
            padding: 0.75rem;

            .svg-icon {
              margin-right: 0.5rem;
            }

            &.active {
              background-color: ${(props) =>
                transparentize(props.theme.colors.secondary, 0.92)};

              .svg-icon {
                fill: ${(props) => props.theme.colors.secondary};
              }
            }
          }
        }
      }
    }

    .pagination-container {
      border: none;
      justify-content: center;
      background-color: ${(props) =>
        lighten(props.theme.colors.secondary, 0.4)};
      position: relative;

      .pagination-block {
        .pagination-btn-group {
          position: absolute;
          right: 1rem;
          top: 50%;
          transform: translateY(-50%);

          .pagination-btn {
            height: 3.125rem;
            width: 3.125rem;

            .svg-icon {
              height: 2.25rem;
              width: 2.25rem;
            }
          }
        }
      }
    }
  }

  .desktop-product-list {
    .product-table-container {
      width: 100%;
      border: 1px solid ${(props) => props.theme.colors.primary};
      max-height: calc(100vh - 5rem - 9.5rem);
      overflow: auto;

      @media (orientation: landscape) and (max-height: 45rem) {
        max-height: 40rem;
      }
    }

    .table {
      width: 100%;
      font-size: 0.875rem;

      .table-head {
        th {
          padding: 0.5rem 0.75rem;
          font-size: 0.75rem;
          font-weight: 500;
          text-transform: uppercase;
          line-height: 1.6;
          text-align: left;
          position: sticky;
          top: 0;
          z-index: 10;
          cursor: pointer;
          vertical-align: middle;

          background-color: ${(props) => props.theme.colors.primary};
          color: ${(props) => props.theme.colors.white};

          .svg-icon {
            margin-left: 0.5rem;
            height: 18px;
            min-width: 18px;
            fill: ${(props) => props.theme.colors.white};

            &.active {
              fill: ${(props) => props.theme.colors.secondary};
            }
          }
        }
      }

      .table-body {
        tr {
          &:nth-of-type(even) {
            background-color: #dcdcdd;
          }

          td {
            padding: 0.125rem 0.75rem;
            vertical-align: middle;

            .icon-button {
              height: 1.25rem;
              width: 1.25rem;
              min-width: 1.25rem;
              min-height: 1.25rem;
              margin-left: 0.75rem;

              .svg-icon {
                height: 1.125rem;
                width: 1.125rem;
              }
            }
          }
        }
      }
    }
  }

  .mobile-product-list {
    display: none;
  }

  @media (max-width: 1320px) {
    .desktop-search-container {
      .cart-toggle {
        display: flex;
      }
    }
  }

  @media (max-width: 1024px) {
    .desktop-search-container {
      display: grid;
      grid-template-columns: auto 1fr auto;
      align-self: center;
      grid-gap: 1rem;

      .cart-toggle {
        display: none;
      }
    }
  }

  ${(props) => props.theme.customBreakpoints[0]} {
    .desktop-search-container {
      display: none;
    }

    .mobile-search-container {
      display: block;
    }

    .desktop-product-list {
      display: none;
    }

    .mobile-product-list {
      display: block;
    }
  }

  .no-data {
    text-align: center;
    padding: 3rem 1rem;
    font-size: 1.25rem;
  }
`;

const SubContainer = styled(Box)`
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.primary};
  border-radius: 0.3rem;
  padding: 1rem;
  font-size: 0.875rem;
  max-height: calc(100vh - 4rem - 7rem);
  height: 100%;
  position: sticky;
  top: 3rem;
  display: flex;
  flex-direction: column;
  &.empty-cart {
    height: auto;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${(props) => props.theme.colors.primary};
    border-bottom: 1px solid ${(props) => props.theme.colors.primary};
    padding: 0.5rem;
    margin: -1rem -1rem 0rem -1rem;

    .svg-icon {
      margin-right: 0.75rem;
    }
  }

  .footer {
    margin-top: auto;
  }

  @media (max-height: 45rem) {
    max-height: none;
  }
`;

const FilterContainer = styled(SubContainer)`
  &.drawer-container {
    flex: 1 1 auto;
    max-height: none;
    max-width: 24rem;
    padding: 1rem;
    width: 100%;

    background-color: ${(props) => props.theme.colors.white};
    border-radius: 0;
    border: none;
    box-shadow: ${(props) => props.theme.shadows.dropDown};

    .header {
      font-size: 1.25rem;
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      margin-bottom: 1.5rem;
      position: relative;

      .icon-btn {
        display: flex;
        position: absolute;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);

        .svg-icon {
          margin: 0;
        }
      }
    }

    .filter-list {
      overflow: hidden;
      overflow-y: auto;
      height: 100%;
      max-height: calc(100vh - 10rem);

      @media (max-height: 40rem) {
        min-height: 20rem;
      }
    }

    .filter-actions {
      margin: 0 0.5rem;
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      > button {
        margin: 0 0.5rem;
        flex: 1 1 auto;
      }
    }
  }
`;

const CartContainer = styled(SubContainer)`
  .cart-list-container {
    // max-height: calc(100vh - 34rem);
    overflow: hidden;
    overflow-y: auto;
    margin: 0 -1rem 0rem -1rem;
    border-bottom: 1px solid ${(props) => props.theme.colors.lightGray};
    @media (max-height: 40rem) {
      min-height: 20rem;
    }
  }

  .cart-item {
    padding: 0.5rem 1rem;
    transition: background 0.3s ease;

    .description {
      font-style: italic;
    }

    &:hover {
      background-color: ${(props) => props.theme.colors.lightGray};
    }
  }

  .confirm-order-container {
    display: flex;
    justify-content: center;
    margin-bottom: 1rem;

    .btn {
      width: 10rem;
      padding: 0.375rem 1rem;
    }
  }
`;

const NoAccessMessage = styled(Box)`
  text-align: center;
  padding: 1.5rem 1rem;
  border-radius: 0.25rem;
  background-color: ${(props) => props.theme.colors.secondary};
  color: ${(props) => props.theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: center;

  .svg-icon {
    margin-bottom: 0.75rem;
  }
`;

const Shop = () => {
  const { t } = useTranslation();
  const { search: searchQuery } = useLocation();
  const { search: searchText } = qs.parse(searchQuery, {
    ignoreQueryPrefix: true,
  });

  const topPriceArticleSearch = qs.parse(searchQuery, {
    ignoreQueryPrefix: true,
  }).title;

  const topPriceArticelNumber = qs.parse(searchQuery, {
    ignoreQueryPrefix: true,
  }).article_no;

  const [editData] = useEditMode();
  const { orderId, mode } = editData || {};

  const cart = useCartState();
  const [showCart, setShowCart] = useContext(MobileCartContext);
  const {
    addItem,
    updateItem,
    setShippingAddressId,
    clearCart,
  } = useCartActions();
  const history = useHistory();
  const toast = useToast();
  const isCOAEnabled = useCOAStatus();
  const isMobileView = useMediaQuery("(max-width: 575px)", true, false);

  const [sort, setSort] = useState({
    sort_by: null,
    sort_direction: "asc",
  });
  const [categories, setCategories] = useState([]);
  const [availability, setAvailability] = useState("all");
  const [search, setSearch] = useState(
    searchText || topPriceArticleSearch || ""
  );
  const [filters, setFilters] = useState({ search });
  const [showFilter, setShowFilter] = useState(false);
  const [topPriceArticle, setTopPriceArticle] = useState(
    topPriceArticelNumber || null
  );
  const {
    isLoading: isCategoriesLoading,
    data: { data: categoryList } = { data: [] },
  } = useQuery("categories", getCategories);

  const {
    isFetching,
    isFetchingMore,
    canFetchMore,
    fetchMore,
    data,
    error,
  } = useInfiniteQuery(
    [
      "stock-list",
      filters?.categories,
      filters?.availability,
      filters?.search,
      topPriceArticle,
      "",
    ],
    getStockList,
    {
      keepPreviousData: !topPriceArticle,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchInterval: refetchIntervalValues.stockListValue,
      getFetchMore: (response) => {
        return response.next_page_url ? response.current_page + 1 : null;
      },
    }
  );
  const stockList = useMemo(() => {
    return (
      data?.reduce((acc, item) => {
        const bar = item.data.map(
          ({ quantity, next_delivery_amount, stock_status, ...item }) => {
            const cartItem = cart.items.find(
              (cartItem) => cartItem.article_no === item.article_no
            );

            return {
              ...item,
              max_quantity: quantity,
              next_delivery_amount,
              stock_status,
              is_added_to_cart: Boolean(cartItem),
              cart_quantity: cartItem?.quantity,
            };
          }
        );

        return acc.concat(bar);
      }, []) || []
    );
  }, [cart.items, data]);

  const {
    data: orderDetailResponse,
    isLoading: isLoadingOrderDetail,
  } = useQuery(["order-detail", orderId, mode], getOrderDetail, {
    enabled: !!orderId,
    retry: false,
    onError: (err) => {
      exceptionHandler(err, (message) => {
        toast(message, { type: "error" });
        clearCart();
        history.push({
          pathname: PageRoutes.MY_ORDERS,
          state: { orderId },
        });
      });
    },
  });

  const orderDetail = orderDetailResponse?.data;

  const onSortClick = (value) => {
    if (value !== sort.sort_by) {
      setSort({ sort_by: value, sort_direction: SortDirection.ASC });
      return;
    }
    if (SortDirection.ASC === sort.sort_direction) {
      setSort({ sort_by: value, sort_direction: SortDirection.DESC });
      return;
    }
    setSort({ sort_by: null, sort_direction: SortDirection.ASC });
  };

  const onConfirmClick = () => history.push(PageRoutes.CART);

  const sortedStockList = useMemo(() => {
    let data = [];
    switch (sort.sort_by) {
      case StockListSortyBy.DESCRIPTION:
        data = stockList.sort((a, b) => {
          const comparison = a.description.localeCompare(b.description);
          return sort.sort_direction === SortDirection.ASC
            ? comparison
            : comparison * -1;
        });
        break;
      case StockListSortyBy.NEXT_DELIVERY_DATE:
        data = stockList.sort((a, b) => {
          const comparison = a.next_delivery_date.localeCompare(
            b.next_delivery_date
          );
          return sort.sort_direction === SortDirection.ASC
            ? comparison
            : comparison * -1;
        });
        break;
      case StockListSortyBy.SELLING_PRICE:
        data = stockList.sort((a, b) => {
          const comparison = a.selling_price > b.selling_price ? 1 : -1;
          return sort.sort_direction === SortDirection.ASC
            ? comparison
            : comparison * -1;
        });
        break;
      case StockListSortyBy.YUKA_POINTS:
        data = stockList.sort((a, b) => {
          const comparison = a.reward_points > b.reward_points ? 1 : -1;
          return sort.sort_direction === SortDirection.ASC
            ? comparison
            : comparison * -1;
        });
        break;
      default:
        data = stockList;
    }
    return [...data];
  }, [stockList, sort.sort_by, sort.sort_direction]);

  useQuery(["order-lock", orderId], lockOrder, {
    enabled: !!orderDetail,
    staleTime: 0,
    refetchInterval: refetchIntervalValues.orderDetailValue,
    retry: false,
    onError: (err) => {
      if (err.response && err.response.status === RequestStatus.ERROR) {
        toast(t("update_timeout"), { type: "error" });
      } else {
        exceptionHandler(err, (message) => {
          toast(message, { type: "error" });
        });
      }

      clearCart();
      history.push({
        pathname: PageRoutes.MY_ORDERS,
        state: { orderId },
      });
    },
  });

  useEffect(() => {
    if (orderDetail) {
      orderDetail.items.forEach((item) => {
        const {
          artnr,
          description,
          price,
          quantity,
          max_quantity,
          reward_points = 0,
          is_transport_insurance = false,
        } = item;
        if (!is_transport_insurance) {
          addItem({
            article_no: artnr,
            description,
            selling_price: price,
            quantity,
            max_quantity: max_quantity,
            total_amount: quantity * price,
            reward_points,
            total_reward_points: quantity * reward_points,
          });
        }
      });
      setShippingAddressId(orderDetail.address_id);
    }
  }, [Boolean(orderDetail)]);

  const desktopListView = useMemo(() => {
    if (isMobileView) {
      return null;
    }

    const view = sortedStockList.map((data, index) => {
      const { max_quantity, mvk, ...rest } = data;
      let maxQuantity = mvk > 0 ? 10 : max_quantity;
      if (orderId) {
        const orderDetailItem = cart.items.find(
          (item) => item.article_no === data.article_no
        );
        if (orderDetailItem) {
          maxQuantity = orderDetailItem.max_quantity;
        }
      }
      return (
        <ShopTableRow
          key={index}
          data={{ ...rest, max_quantity: maxQuantity }}
          onAddToCart={addItem}
          onUpdateToCart={updateItem}
        />
      );
    });

    if (canFetchMore) {
      view.push(
        <Waypoint
          onEnter={() => fetchMore()}
          key={view.length}
          bottomOffset={"-250%"}
        >
          <tr>
            <td colSpan={5}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Spinner />
              </Box>
            </td>
          </tr>
        </Waypoint>
      );
    }

    return view;
  }, [
    isMobileView,
    sortedStockList,
    canFetchMore,
    orderId,
    addItem,
    updateItem,
    cart.items,
    fetchMore,
  ]);

  const mobileListView = useMemo(() => {
    if (!isMobileView) {
      return null;
    }

    const view = sortedStockList.map((data, index) => {
      const { max_quantity, ...rest } = data;
      let maxQuantity = max_quantity;
      if (orderId) {
        const orderDetailItem = cart.items.find(
          (item) => item.article_no === data.article_no
        );
        if (orderDetailItem) {
          maxQuantity = orderDetailItem.max_quantity;
        }
      }

      return (
        <MobileViewProductItem
          key={index}
          data={{ ...rest, max_quantity: maxQuantity }}
          onAddToCart={addItem}
          onUpdateToCart={updateItem}
        />
      );
    });

    if (canFetchMore) {
      view.push(
        <Waypoint
          onEnter={() => fetchMore()}
          key={view.length}
          bottomOffset={"-250%"}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Spinner />
          </Box>
        </Waypoint>
      );
    }

    return view;
  }, [
    isMobileView,
    sortedStockList,
    canFetchMore,
    orderId,
    addItem,
    updateItem,
    cart.items,
    fetchMore,
  ]);

  const showFilterCountBadge =
    (filters?.categories && filters?.categories.length > 0) ||
    (filters?.availability && filters.availability !== "all");
  const filterCount =
    (filters?.categories ? filters.categories.length : 0) +
    (filters?.availability ? (filters.availability !== "all" ? 1 : 0) : 0);

  const listView = isMobileView ? (
    <Box className="mobile-product-list" id={"mobile-product-list"}>
      <WithLoader isLoading={isFetching && !isFetchingMore} height={"24rem"}>
        {!(isFetching && !isFetchingMore) && sortedStockList.length === 0 && (
          <Box className="no-data">
            <span>{t("no_data_in_the_list")}</span>
          </Box>
        )}
        {!(isFetching && !isFetchingMore) && mobileListView}
      </WithLoader>
    </Box>
  ) : (
    <Box className="desktop-product-list">
      <Box className="product-table-container">
        <table className="table">
          <thead className="table-head">
            <tr>
              <SortTableHead
                label={t("item_description")}
                value={"description"}
                sort={sort}
                onClick={onSortClick}
              />
              <th>{t("european_article_number_short_and_part_number")}</th>
              <th>{t("quantity")}</th>
              <SortTableHead
                label={t("price")}
                value={"selling_price"}
                sort={sort}
                onClick={onSortClick}
              />
              <SortTableHead
                label={t("yuka_points")}
                value={"yuka_points"}
                sort={sort}
                image={YPWhite}
                onClick={onSortClick}
              />
              <SortTableHead
                label={t("est_delivery")}
                value={"next_delivery_date"}
                sort={sort}
                icon={
                  <IconTruck
                    height="18px"
                    width="18px"
                    style={{ transform: "scaleX(-1)" }}
                  />
                }
                onClick={onSortClick}
              />
            </tr>
          </thead>
          <tbody className="table-body" id={"desktop-product-table-body"}>
            {isFetching &&
              !isFetchingMore &&
              [1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => (
                <tr key={i}>
                  <td colSpan={6}>
                    <Skeleton />
                  </td>
                </tr>
              ))}
            {!(isFetching && !isFetchingMore) && sortedStockList.length === 0 && (
              <tr>
                <td colSpan={5} className="no-data">
                  {t("no_data_in_the_list")}
                </td>
              </tr>
            )}
            {!(isFetching && !isFetchingMore) && desktopListView}
          </tbody>
        </table>
      </Box>
    </Box>
  );

  const searchView = isMobileView ? (
    <Box className="mobile-search-container">
      <Input
        label={t("search_ean")}
        type="search"
        icon={
          <IconSearch
            onClick={() => {
              setFilters({ ...filters, search });
            }}
          />
        }
        iconPlacement="suffix"
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
          setTopPriceArticle(null);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setFilters({ ...filters, search });
          }
        }}
        onBlur={() => {
          if (search === "") {
            setFilters({ ...filters, search });
          }
        }}
      />
      <Box className="filter-sort-container">
        <Button
          variant="secondaryOutline"
          className="filter-toggle"
          onClick={(e) => {
            e.preventDefault();
            setShowFilter((prev) => !prev);
          }}
        >
          {t("filter")}
          <IconFilter />

          {showFilterCountBadge && (
            <Badge
              variant="cartBadge"
              sx={{
                position: "absolute",
                top: "calc(50% - 0.75rem)",
                backgroundColor: "primary",
              }}
            >
              {filterCount}
            </Badge>
          )}
        </Button>

        <Menu
          label={t("sort")}
          icon={<IconSortUp />}
          showDropdownIcon={false}
          className="sort-toggle"
          contentStyle={{ minWidth: "14rem" }}
          items={[
            {
              label: t("item_description"),
              value: "description",
              sortOption: "asc",
            },
            {
              label: t("item_description"),
              value: "description",
              sortOption: "desc",
            },
            {
              label: t("yuka_points"),
              value: "yuka_points",
              sortOption: "asc",
            },
            {
              label: t("yuka_points"),
              value: "yuka_points",
              sortOption: "desc",
            },
            {
              label: t("price"),
              value: "price",
              sortOption: "asc",
            },
            {
              label: t("price"),
              value: "price",
              sortOption: "desc",
            },
            {
              label: t("est_delivery"),
              value: "next_delivery_date",
              sortOption: "asc",
            },
            {
              label: t("est_delivery"),
              value: "next_delivery_date",
              sortOption: "desc",
            },
          ].map((item) => ({
            label: (
              <Fragment>
                {item.sortOption === "asc" ? <IconSortUp /> : <IconSortDown />}
                {item.label}
              </Fragment>
            ),
            isActive:
              sort.sort_by === item.value &&
              sort.sort_direction === item.sortOption,
            onClick: () => {
              setSort({
                sort_by: item.value,
                sort_direction: item.sortOption,
              });
            },
          }))}
        />
      </Box>
    </Box>
  ) : (
    <Box className="desktop-search-container">
      <Button
        variant="secondary"
        className="filter-toggle"
        onClick={(e) => {
          e.preventDefault();
          setShowFilter((prev) => !prev);
        }}
      >
        {t("filter")}
        {showFilterCountBadge && (
          <Badge
            variant="cartBadge"
            sx={{
              position: "absolute",
              right: "-0.25rem",
              top: "-0.25rem",
              backgroundColor: "primary",
            }}
          >
            {filterCount}
          </Badge>
        )}
      </Button>
      <Input
        label={t("search_ean")}
        type="search"
        icon={
          <IconSearch
            onClick={() => {
              setFilters({ ...filters, search });
            }}
          />
        }
        iconPlacement="suffix"
        value={search}
        onChange={(e) => {
          setSearch(e.target.value);
          setTopPriceArticle(null);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            setFilters({ ...filters, search });
          }
        }}
        onBlur={() => {
          if (search === "") {
            setFilters({ ...filters, search });
          }
        }}
      />
      <Button
        variant="primaryIconButton"
        className="cart-toggle"
        onClick={(e) => {
          e.preventDefault();
          setShowCart((prev) => !prev);
        }}
      >
        <IconShoppingCart />
      </Button>
    </Box>
  );

  // const message = DOMPurify.sanitize(
  //     "Dear partners,<br>please note that our webshop will be unavailable from 07:00pm to 11:00pm UTC+2 on June 7, 2021. During this time it will not be possible to place or update orders. Please place your orders before the scheduled shutdown time. Thank you for your understanding"
  // );

  if (error?.response?.status === 403) {
    return (
      <Flex
        sx={{
          alignItems: "center",
          paddingTop: "5em",
          fontWeight: "bold",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Text sx={{ fontSize: 20, color: themes.colors.red }}>
          {t("access_restricted_title")}
        </Text>
        <Text
          sx={{
            fontWeight: "bold",
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          {t("access_restricted_content")}
        </Text>
      </Flex>
    );
  }

  const message = null;

  return (
    <div>
      {message && (
        <Box
          style={{
            border: "2px dashed red",
            margin: "10px",
            textAlign: "center",
            padding: "10px",
            backgroundColor: "lightyellow",
          }}
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      <ShopPageContainer>
        {/* Filter Section */}
        <Flex
          className={`drawer-wrapper drawer-filter ${
            showFilter ? "opened" : ""
          }`}
        >
          <FilterContainer className={`drawer-container`}>
            <Box className="header">
              <Heading>{t("filters")}</Heading>
              <Button
                variant="secondaryIconButton"
                className="icon-btn"
                onClick={() => {
                  setShowFilter((active) => !active);
                }}
              >
                <IconClose />
              </Button>
            </Box>
            <Box className="filter-list">
              <MultiCheckboxFilter
                isLoading={isCategoriesLoading}
                data={categoryList}
                value={categories}
                onChange={(categories) => {
                  setCategories(categories);
                }}
              />
              <RadioFilter
                data={[
                  { id: "all", name: "all" },
                  { id: "available", name: "available" },
                  { id: "upcoming", name: "upcoming" },
                  {
                    id: "availablewithupcoming",
                    name: "availablewithupcoming",
                  },
                  { id: "unavailable", name: "unavailable" },
                ]}
                defaultId={"all"}
                onChange={(id) => {
                  setAvailability(id);
                }}
                value={availability}
              />
            </Box>
            <Box className="filter-actions">
              <Button
                variant="primaryOutline"
                className="btn"
                onClick={() => {
                  setCategories([]);
                  setAvailability("all");
                  setSearch("");
                  setFilters(null);
                  setShowFilter(false);
                  setTopPriceArticle(null);
                }}
              >
                {t("reset")}
              </Button>
              <Button
                variant="secondary"
                className="btn"
                onClick={() => {
                  setFilters({ ...filters, availability, categories });
                  setShowFilter(false);
                  setTopPriceArticle(null);
                }}
              >
                {t("apply_filter")}
              </Button>
            </Box>
          </FilterContainer>
          <Box
            className="outside-click"
            onClick={() => {
              setShowFilter((active) => !active);
            }}
          />
        </Flex>

        {/* Product List Section */}
        <ProductContainer className="product-container">
          {searchView}
          {listView}
        </ProductContainer>
        {/* Shopping Cart Section */}
        <Flex
          className={`drawer-wrapper drawer-cart ${showCart ? "opened" : ""}`}
        >
          <Box
            className="outside-click"
            onClick={() => {
              setShowCart((prev) => !prev);
            }}
          />
          {isCOAEnabled ? (
            <CartContainer
              className={`drawer-container ${
                cart?.items?.length === 0 ? "empty-cart" : ""
              }`}
            >
              <Box className="header">
                <IconShoppingCart />
                <Heading>
                  {orderId ? t("order_items") : t("your_cart_items")}
                </Heading>
                <Button
                  variant="secondaryIconButton"
                  className="icon-btn"
                  onClick={() => {
                    setShowCart((prev) => !prev);
                  }}
                >
                  <IconClose />
                </Button>
              </Box>
              <WithLoader isLoading={isLoadingOrderDetail}>
                <Cart
                  onConfirmClick={onConfirmClick}
                  onOrderEditMode={Boolean(orderId)}
                  isUpdatingOrder={false}
                  errors={[]}
                />
              </WithLoader>
            </CartContainer>
          ) : (
            //@todo design for no coa access
            <NoAccessMessage>
              <IconAlert height="3rem" width="3rem" fill="currentColor" />
              <Text>{t("unconfirmed_coa_alert")}</Text>
            </NoAccessMessage>
          )}
        </Flex>
      </ShopPageContainer>
    </div>
  );
};
export default Shop;
